import React, { useEffect } from "react";
import HomeScreen from "./HomeScreen";
import { useParams } from "react-router-dom";
import axios from "axios";

const HomePage = () => {
  const { username } = useParams();

  const getUserData = async (user: string) => {
    const response = await axios.get(
      `https://cruncher.asset.money/user/mn/${user}`
    );
    let data = response.data;

    if (data?.data) {
      let usernameOfUser = data?.data?.username;
      let uuidOfUser = data?.data?.uuid;

      localStorage.setItem("ref", usernameOfUser);
      axios
        .post("https://cruncher.asset.money/misc/referral", {
          referralCode: uuidOfUser,
          referreduser: "",
        })
        .then((response) => {
          console.log("response");
        });
    }
  };

  useEffect(() => {
    if (username && username?.length > 0) {
      const checkInLocalStorage = localStorage.getItem("ref");
      if (!checkInLocalStorage) {
        getUserData(username);
      }
    }
  }, [username]);

  return <HomeScreen />;
};

export default HomePage;
