import React, { useState, useRef, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { FaCheck } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { getArtistSongs, postUserQuizAttempt, getArtistInfo } from "../endpoints/api";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { ImCross } from "react-icons/im";
import MuzifyLogo from "../assets/images/logo.png";
import { RxLoop } from "react-icons/rx";
// import { ShimmerThumbnail } from "react-shimmer-effects";
// import { ShimmerTitle } from "react-shimmer-effects";
import Skeleton from "react-loading-skeleton";
import useAsset from "../hooks/useAsset";
import useClevertap from "../hooks/useClevertap";
import LoginOverlay from "../components/LoginOverlay";
import { set } from "lodash";
import { useStopwatch } from 'react-timer-hook';
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_ACCOUNT_ID, META_EVENTS, TIKTOK_EVENTS } from "../constants/dataConstant";
import { trackMetaEvent } from "../tracking/metaPixel";
import { MUSICNERD_ARTIST_QUIZ_ANSWERED_, MUSICNERD_ARTIST_QUIZ_STARTED } from "../constants/events";
import { trackTikTokEvent } from "../tracking/tiktokPixel";

const AudioPlayer = () => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  let { id } = useParams();
  const timeoutRef = useRef(null);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [audioSource, setAudioSource] = useState("");
  const [quizData, setQuizData] = useState([]);
  const [artistName, setArtistName] = useState("");
  const [level, setLevel] = useState(0);
  const [answer, setAnswer] = useState({
    right: 0,
    time: 0,
  });
  // const [seconds, setSeconds] = useState(0);
  const [second, setSecond] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [replay, setReplay] = useState(false);
  const [shimmerLoading, setShimmerLoading] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [authLoad, setAuthLoad] = useState(true);
  // const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [milliseconds, setMilliseconds] = useState(0);
  const { userInfo, userData } = useAsset();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();
  const { handleEventPushClick } = useClevertap();

  // console.log(location, "location")
  useEffect(() => {
    if (localStorage.getItem('loggedIn') === 'true') {
      console.log('logged in');
      clevertap.setLogLevel(3);
      clevertap.init(CLEVERTAP_ACCOUNT_ID);
    }
  }, []);

  const handleImageClick = (imageIndex, correct) => {
    if (correct) {
      var right = answer.right + 10;
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        right: right,
      }));
      posthog?.capture(`artist_quiz_answered_${level}`, {
        artist_id: id,
        answer: "correct",
      });
      handleEventPushClick(`${MUSICNERD_ARTIST_QUIZ_ANSWERED_}${level}`, {
        artist_id: id,
        answer: "correct",
      });
    }
    posthog?.capture(`artist_quiz_answered_${level}`, {
      artist_id: id,
      answer: "wrong",
    });
    handleEventPushClick(`${MUSICNERD_ARTIST_QUIZ_ANSWERED_}${level}`, {
      artist_id: id,
      answer: "wrong",
    });
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      time: prevAnswer.time + second,
    }));
    setTimerRunning(false);
    setButtonEnabled(false);
    setSelectedImage(imageIndex);
  };

  const handleNext = () => {
    if (level === quizData?.length) {
      var time = (minutes * 60) + seconds;
      console.log("TIME ", time);
      trackMetaEvent("trackCustom", META_EVENTS.QUIZ_COMPLETED, userInfo?.email ? userInfo?.email : userData?.email, userInfo?.uuid ? userInfo?.uuid : userData?.uid, {
        content_name: 'Quiz Completed',
        score: answer.right,
        quiz_id: id
      })
      trackTikTokEvent(TIKTOK_EVENTS.QUIZ_COMPLETED, userInfo?.email ? userInfo?.email : userData?.email, userInfo?.uuid ? userInfo?.uuid : userData?.uid, {
        content_id: id,
        content_category: "Quiz Completed",
        content_name: answer.right.toString()
      })
      handleEventPushClick("MUSICNERD_ARTIST_QUIZ_COMPLETED", {
        artist_id: id,
        score: answer.right,
        time: time,
      })
      if (window.location.href.includes("musicnerd.io")) {
        navigate(`/score/${id}`, {
          state: {
            artist_name: location.state?.artistName,
            artist_image: location.state?.artistImage,
            right: answer.right,
            time: time,
          },
        });
      } else {
        navigate(`/quiz/score/${id}`, {
          state: {
            artist_name: location.state?.artistName,
            artist_image: location.state?.artistImage,
            right: answer.right,
            time: time,
          },
        });
      }
      return;
    }

    setCurrentTime(0);
    setAudioSource(quizData[level]?.preview_url);
    setSecond(0);
    setTimerRunning(true);
    setTimeout(() => {
      setShimmerLoading(false);
    }, 1000);
    setLevel(level + 1);
    setShimmerLoading(true);
    setSelectedImage(null);
    setButtonEnabled(true);
    setReplay(false);
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      audioRef.current.load();
    }
  };
  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      const maxDuration = 5;
      const duration = Math.min(audioRef.current.duration, maxDuration);
      setDuration(duration);
    }
  };
  const getArtistData = async (id) => {
    const response = await getArtistSongs(id);
    if (response.data.data.artist_quiz.length !== 0) {
      setLevel(1);
    }
    const randomizedQuizData = response.data.data.artist_quiz.map(
      (quizItem) => {
        const maxDuration = 5;
        const startTime = Math.random() * (maxDuration - 10);
        const endTime = startTime + 5;
        const preview_url = `${quizItem.preview_url}#t=${startTime.toFixed(
          2
        )},${endTime.toFixed(2)}`;

        return { ...quizItem, preview_url };
      }
    );

    // setArtistName(response.data.data.artist_name);
    setQuizData(randomizedQuizData);
    setAudioSource(randomizedQuizData[0].preview_url);
    setAudioSource(response.data.data.artist_quiz[0].preview_url);
  };

  const getArtistInfoFromApi = async (id) => {
    try {
      const response = await getArtistInfo(id)
      if (response && response.data && response.data.data) {
        if (response.data.data.artist_name) {
          setArtistName(response.data.data.artist_name)
        }
      }
    } catch (error) {
      console.log("Error retrieving artist data", error)
    }
  }

  useEffect(() => {
    let intervalId;
    if (timerRunning || level === 1) {
      intervalId = setInterval(() => {
        setSecond((prevSeconds) => prevSeconds + 1);
        setSecond((prevSeconds) => prevSeconds + 1)
        setInterval(() => {
          setMilliseconds((prevMilliseconds) => prevMilliseconds + 1);
        }, 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timerRunning, level]);

  useEffect(() => {
    if (id) {
      getArtistInfoFromApi(id);
      getArtistData(id);
    }
  }, [id]);

  useEffect(() => {
    if (audioRef.current && level <= quizData?.length) {
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (audioRef.current && level <= quizData?.length) {
        audioRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, [id, level, quizData]);

  useEffect(() => {
    if (!buttonEnabled && level !== quizData.length) {
      timeoutRef.current = setTimeout(() => {
        console.log("This got called for level", level)
        handleNext();
      }, 3000);
    } else if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [buttonEnabled]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else if (replay) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
      setReplay(false);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      if (audioRef.current.currentTime >= 5) {
        setReplay(true);
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const renderLines = () => {
    const lines = [];
    const numberOfLines = 20;

    for (let i = 0; i < numberOfLines; i++) {
      const lineHeight = i % 3 === 0 ? "30px" : i % 2 === 0 ? "24px" : "20px";
      const paddingTop = i % 3 === 0 ? "0px" : i % 2 === 0 ? "5px" : "7px";

      const lineStyle = {
        width: "4px",
        height: lineHeight,
        backgroundColor:
          i < (currentTime / duration) * numberOfLines
            ? "rgba(239, 209, 101, 1)"
            : "rgba(173, 173, 173, 1)",
        marginRight: "4px",
        marginTop: paddingTop,
        borderRadius: "5px",
      };

      lines.push(<div key={i} style={lineStyle}></div>);
    }

    return lines;
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === " ") {
        handlePlayPause();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePlayPause]);

  useEffect(() => {
    if (location.state?.artistName?.length > 0) {
      document.title = `Play the ${location.state?.artistName} Music Quiz | Test Your Song Knowledge on Muzify`;

      // Update meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", `How well do you know ${location.state?.artistName}? Take the ultimate ${location.state?.artistName} music quiz on Muzify, challenge fellow fans, and show off your song knowledge.`);
      }

      // Update meta keywords
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute("content", `Music Quiz, ${location.state?.artistName} Music Quiz, true music fan, music quiz, super fan, muzify, ${location.state?.artistName} quiz, guess the song by ${location.state?.artistName}, ${location.state?.artistName} music trivia, fan quiz for ${location.state?.artistName}, musicnerd quiz, music nerd, muzify quiz, concert, music concert`);
      }

      // Update canonical URL
      const canonicalTag = document.querySelector('link[rel="canonical"]');
      if (canonicalTag) {
        canonicalTag.setAttribute("href", `${window.location.origin}/quiz/${id}`);
      }
      handleEventPushClick(MUSICNERD_ARTIST_QUIZ_STARTED, {
        artist_id: id,
        artist_name: location.state?.artistName,
      });
      trackMetaEvent("trackCustom", META_EVENTS.QUIZ_STARTED, userInfo?.email ? userInfo?.email : userData?.email, userInfo?.uuid ? userInfo?.uuid : userData?.uid, {
        content_name: `${location.state?.artistName} Quiz`,
        artist_name: location.state?.artistName,
        quiz_id: id
      })
      // console.log("Quiz started uuid", userInfo)
      trackTikTokEvent(TIKTOK_EVENTS.QUIZ_STARTED, userInfo?.email ? userInfo?.email : userData?.email, userInfo?.uuid ? userInfo?.uuid : userData?.uid, {
        content_id: id,
        content_category: `${location.state?.artistName} Quiz`,
        content_name: location.state?.artistName
      })
    }
  }, [location.state?.artistName]);

  useEffect(() => {
    setAuthLoad(true);
    let firstTimePlay = localStorage.getItem("firstTimePlay");
    let loggedIn = localStorage.getItem("loggedIn");

    if (firstTimePlay == "false" && (loggedIn == "false" || loggedIn === null)) {
      if (location.state?.artistName && !userInfo) {
        setShowAuth(true);
        setAuthLoad(false);
      } else {
        setShowAuth(false);
        setAuthLoad(false);
      }
    }
  }, [!!userInfo, location.state?.artistName]);

  useEffect(() => {
    // console.log("In new useEffect")
    const logQuizAttempt = async (uuid, artistId) => {
      const body = {
        uuid,
        artistId
      }
      try {
        const resp = await postUserQuizAttempt(body)
        if (resp && resp.data && resp.data.success) {
          console.log("Attempt logged")
        }
      } catch (error) {
        console.error("Error when logging the attempt", error)
      }
    }

    if (userInfo && id) {
      // console.log("This got called!!!")
      logQuizAttempt(userInfo.uuid, id)
    }

  }, [userInfo, id])

  const convertMilliseconds = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const displayMilliseconds = String(ms % 1000).padStart(3, '0');
    return seconds;
  };

  const renderQuizLevels = (level) => {
    const currentQuizData = quizData[level - 1];

    return (
      <div className="bg-[#141A1E]">

        <audio
          ref={audioRef}
          src={quizData[level - 1]?.preview_url}
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => {
            setIsPlaying(false);
            setReplay(true);
          }}
        />
        <div className="flex items-center justify-center w-screen">
          <button
            onClick={handlePlayPause}
            className="text-[rgba(239,209,101,1)]"
          >
            {isPlaying ? (
              <div className="circle w-10 h-10 flex items-center justify-center rounded-full">
                <FaPause
                  style={{ color: "rgba(239, 209, 101, 1)" }}
                  size={13}
                />
              </div>
            ) : replay ? (
              <div className="circle bg-[rgba(28,33,36,1)] w-10 h-10 flex items-center justify-center rounded-full">
                <RxLoop style={{ color: "rgba(239, 209, 101, 1)" }} size={15} />
              </div>
            ) : (
              <div className="circle bg-[rgba(28,33,36,1)] w-10 h-10 flex items-center justify-center rounded-full">
                <FaPlay style={{ color: "rgba(239, 209, 101, 1)" }} size={13} />
              </div>
            )}
          </button>
          <span className="ml-2 text-[rgba(167,167,167,1)]">
            {formatTime(currentTime)}
          </span>
          <div className="ml-2 flex">{renderLines()}</div>
          <span className="ml-2 text-[rgba(167,167,167,1)]">
            {formatTime(duration)}
          </span>
        </div>
        <div className="flex flex-col justify-center items-center flex-wrap">
          {/* <p className='text-[rgba(239,209,101,1)] cabinet-grotesk text-center text-xl pb-3'>Select an Option</p> */}
          <div className="flex justify-center items-start flex-wrap md:gap-8 gap-6 py-4">
            {quizData[level - 1]?.options.map((photo, index) => (
              <div
                className="flex flex-col justify-center items-center gap-3 max-w-[24]"
                key={index}
              >
                <div
                  className="flex flex-col items-center md:w-32 w-24 cursor-pointer"
                  onClick={() =>
                    buttonEnabled ? handleImageClick(index, photo.correct) : ""
                  }
                >
                  <div className="relative">
                    {shimmerLoading ? (
                      <>
                        <div className="md:hidden">
                          <Skeleton
                            height={132}
                            width={105}
                            borderRadius={16}
                          />
                        </div>
                        <div className="md:block hidden">
                          <Skeleton
                            height={145}
                            width={133}
                            borderRadius={16}
                          />
                        </div>
                      </>
                    ) : (
                      <img
                        src={photo?.album_image}
                        alt="artist1"
                        style={{
                          opacity:
                            selectedImage === index ||
                              (selectedImage !== index &&
                                photo.correct &&
                                !buttonEnabled)
                              ? "1"
                              : selectedImage !== index && !buttonEnabled
                                ? "0.6"
                                : "1",
                        }}
                        className={`h-28 md:h-36 w-full rounded-xl object-cover  
                      ${(selectedImage === index && photo.correct) ||
                            (selectedImage !== index &&
                              photo.correct &&
                              !buttonEnabled)
                            ? "border-green-500 border-2"
                            : selectedImage === index && !photo.correct
                              ? "border-red-500 border-2"
                              : ""
                          }
                        `}
                      />
                    )}
                    {(selectedImage === index && photo.correct && !shimmerLoading) ||
                      (selectedImage !== index &&
                        photo.correct &&
                        !buttonEnabled && !shimmerLoading) ? (
                      <div
                        className="tick-mark absolute"
                        style={{ top: "-5px", right: "-5px" }}
                      >
                        <div className="circle bg-green-500 w-5 h-5 flex items-center justify-center rounded-full">
                          <FaCheck color="white" size={10} height={5} />
                        </div>
                      </div>
                    ) : selectedImage === index && !photo.correct && !shimmerLoading ? (
                      <div
                        className="tick-mark absolute"
                        style={{ top: "-5px", right: "-5px" }}
                      >
                        <div className="circle bg-red-500 w-5 h-5 flex items-center justify-center rounded-full">
                          <ImCross color="white" size={10} height={5} />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {shimmerLoading ? (
                    <div className="flex flex-col">
                      <div className="md:hidden">
                        <Skeleton
                          className="p-0 text-xl mt-4 leading-snug"
                          height={9}
                          width={100}
                        />
                        <Skeleton
                          className="p-0 text-xl leading-snug"
                          height={9}
                          width={50}
                        />
                      </div>
                      <div className="md:block hidden">
                        <Skeleton
                          className="p-0 text-xl mt-4 leading-snug"
                          height={9}
                          width={123}
                        />
                        <Skeleton
                          className="p-0 text-xl leading-snug"
                          height={9}
                          width={70}
                        />
                      </div>
                    </div>
                  ) : (
                    <p className="text-white cabinet-grotesk mt-2 text-center">
                      {photo.song_name.length > 30
                        ? `${photo.song_name.slice(0, 25)}...`
                        : photo.song_name}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          {!buttonEnabled && !shimmerLoading ? (
            <button
              className={`bg-[rgba(239,209,101,1)] cabinet-grotesk md:w-80 w-80 h-12 rounded-full button text-black`}
              onClick={handleNext}
            >
              Next
            </button>
          ) : null}
          {!buttonEnabled && !shimmerLoading ? (
            <p className="text-white pt-3 cabinet-grotesk text-center">
              {level === 9
                ? `1 song left`
                : `${Math.abs(level - 10)} songs left`}
            </p>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center md:mt-20 my-28 gap-4 bg-[#141a1e]">
      {showAuth && (
        <LoginOverlay artistName={location.state?.artistName ?? "Music Fan"} artistId={id} artistImage={location.state?.artistImage} setShowAuth={setShowAuth} />
      )}
      <div className="flex justify-center items-center mt-0 mb-3">
        <div className="flex justify-center absolute left-2 md:left-0 right-0">
          <Link className="flex flex-row gap-2" to="/">
            <img src={MuzifyLogo} alt="muzify" className="w-32" />
          </Link>
        </div>
        {userInfo && (
          <div className="flex justify-end mr-5 md:mr-10 absolute right-0 top-8">
            <Link to="/profile">
              <img
                src={userInfo?.profilepic}
                alt=""
                className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-gray-800"
              />
            </Link>
          </div>
        )}
      </div>
      <div className="mt-6 md:mt-8 flex items-center justify-center font-extrabold cabinet-grotesk text-center text-3xl md:text-5xl text-[#FFFFFF]">
        {artistName}
      </div>
      <h1 className="text-[rgba(239,209,101,1)] md:text-4xl md:px-0 px-1 font-extrabold text-center text-2xl cabinet-grotesk">
        Play the track and pick the right song name
      </h1>
      {quizData.length === 0 && (
        <div className="flex flex-col w-full items-center justify-center gap-10">
          {/* <Lottie className="md:w-96" animationData={music} loop={true} /> */}
          <div className="flex flex-row">
            <ScaleLoader color="rgba(239,209,101,1)" aria-setsize={100} />
            <ScaleLoader color="rgba(239,209,101,1)" aria-setsize={100} />
            <ScaleLoader color="rgba(239,209,101,1)" aria-setsize={100} />
            <ScaleLoader color="rgba(239,209,101,1)" aria-setsize={100} />
            <ScaleLoader color="rgba(239,209,101,1)" aria-setsize={100} />
          </div>
        </div>
      )}
      {level >= 1 && level <= quizData?.length && renderQuizLevels(level)}
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
        Muzify
      </a>
      <a className="text-[#141a1e] text-sm" href="https://imfeeling.xyz">
        Imfeeling
      </a>
      <a className="text-[#141a1e] text-sm" href="https://petplaylist.xyz/">
        Pet Playlist
      </a>
    </div>
  );
};

// Helper function to format time in MM:SS format
const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

export default AudioPlayer;
