import { MoonLoader } from "react-spinners"
import { AvatarGenerator } from "random-avatar-generator";

const generator = new AvatarGenerator();

const displayTime = (time) => {
    if  (time < 60) {
        return  `${time}s`;
    }   else if (time >= 60 && time < 3600) {
        return `${Math.floor(time / 60)}m ${time % 60}s`;
    }   else  {
        return `${Math.floor(time / 3600)}h ${Math.floor((time % 3600) / 60)}m ${
            time % 60
        }s`;
    }
};

const UserRow = ({item, playerIndex}) => {
    return (
        <div className="w-full flex flex-col justify-center items-center mt-2">
            <div className="flex flex-row w-[90%] h-[10px] items-center justify-center bg-[#5D4200] rounded-t-lg" />
            <div className="flex flex-row w-[95%] h-[10px] items-center justify-center bg-[#AF7D00] rounded-t-lg" />
            <ScoreRow item={item} index={playerIndex} playerIndex={playerIndex}/>
        </div>
    )
}

const TopRow = ({item, index, playerIndex}) => {
    const truncatedUsername = item.username.length > 15 
    ? item.username.substring(0, 15) + '...' 
    : item.username;

    //via-[#3C1BABCC] via-[#FF3E32CC]

    return (
        <div className="w-full h-[40px] flex flex-col justify-center items-center">
            <div className="flex justify-center items-center w-full rounded-lg h-[40px] bg-gradient-to-r from-[#EA86C5] via-[#3C1BAB] to-[#5DBA32]">
                <div className={`flex flex-row w-[calc(100%-4px)] h-[36px] items-center justify-center rounded-lg ${0 === index ? 'bg-[#FFD56C]' : (playerIndex === index ? 'bg-[#DF9F00]' : '')}`}>
                    <div className="w-[10%] flex justify-center items-center">
                        <div className={`text-[18px] font-[FiraSans] font-bold text-[#070707]`}>{(index + 1).toString()}</div>
                    </div>
                    <div className="w-[50%] flex flex-row items-center justify-start gap-1">
                        <div className="w-[25px] h-[25px] rounded-full overflow-hidden flex justify-center items-center">
                            <img
                                src={
                                  item?.profile_image?.length > 0
                                    ? item?.profile_image
                                    : generator.generateRandomAvatar(item.username)
                                }
                                alt=""
                                className="h-8 w-8 rounded-full bg-gray-800"
                            />
                        </div>
                        <div className={`text-[14px] font-[FiraSans] font-bold text-[#070707]`}>{truncatedUsername}</div>
                    </div>
                    <div className="w-[20%] flex justify-center items-center">
                        <div className={`text-[18px] font-[FiraSans] font-semibold text-[#070707]`}>{item.score.toString()}</div>
                    </div>
                    <div className="w-[20%] flex justify-center items-center">
                        <div className={`text-[18px] font-[FiraSans] font-semibold text-[#070707]`}>{displayTime(item.time)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ScoreRow = ({item, index, playerIndex}) => {
    const truncatedUsername = item.username.length > 15 
    ? item.username.substring(0, 15) + '...' 
    : item.username;

    // if (index === 0){
    //     console.log('Test item', item)
    // }

    return (
        <div className={`flex flex-row w-full h-[40px] items-center justify-center rounded-lg ${0 === index ? 'bg-[#0A0B0D]' : (playerIndex === index ? 'bg-[#DF9F00]' : '')}`}>
            <div className="w-[10%] flex justify-center items-center">
                <div className={`${index === playerIndex && playerIndex > 99 ? 'text-[12px]' : 'text-[18px]'} font-[FiraSans] font-semibold text-[#070707]`}>{(index + 1).toString()}</div>
            </div>
            <div className="w-[50%] flex flex-row items-center justify-start gap-1">
                <div className="w-[25px] h-[25px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                        src={
                            item?.profile_image?.length > 0
                            ? item?.profile_image
                            : generator.generateRandomAvatar(item.username)
                        }
                        alt=""
                        className="h-8 w-8 rounded-full bg-gray-800"
                    />
                </div>
                <div className={`text-[14px] font-[FiraSans] ${index === playerIndex ? 'font-bold' : 'font-medium'} text-[#070707]`}>{truncatedUsername}</div>
            </div>
            <div className="w-[20%] flex justify-center items-center">
                <div className={`text-[18px] font-[FiraSans] font-semibold text-[#070707]`}>{item.score.toString()}</div>
            </div>
            <div className="w-[20%] flex justify-center items-center">
                <div className={`text-[18px] font-[FiraSans] font-semibold text-[#070707]`}>{displayTime(item.time)}</div>
            </div>
        </div>
    )
}

const LeaderboardElement = ({loadingLeaderboard, leaderboardData, playerIndex}) => {
    return (
        <div className={`mt-4 md:mt-0 w-full md:w-[420px] flex flex-col items-center justify-center bg-[#F0B92F] rounded-lg ${loadingLeaderboard ? 'h-[400px]': ''}`}>
            {loadingLeaderboard ? <MoonLoader color="white" size={50} /> :
            <>

            {/* Leaderboard Table */}
            <div className="w-full flex flex-col px-2 mb-4">
                <div className="flex flex-row mt-[8px]">
                    <div className="w-[10%]"/>
                    <div className="w-[50%] flex flex-row justify-start items-center">
                        <div className="text-[18px] text-[#070707] font-[FiraSans] font-semibold">Fans</div>
                    </div>
                    <div className="w-[20%] flex justify-center items-center">
                        <div className="text-[18px] text-[#070707] font-[FiraSans] font-semibold">Score</div>
                    </div>
                    <div className="w-[20%] flex justify-center items-center">
                        <div className="text-[18px] text-[#070707] font-[FiraSans] font-semibold">Time</div>
                    </div>
                </div>
                <div className="flex flex-col mt-[8px]">
                    {
                        leaderboardData ? leaderboardData
                        .filter((_,index) => index < 10)
                        .map((data, index) => {
                            if (index === 0) {
                                return <TopRow key={index} item={data} index={index} playerIndex={playerIndex}/>
                            }
                            return <ScoreRow key={index} item={data} index={index} playerIndex={playerIndex}/>
                        }) : <></>
                    }
                    {
                        (playerIndex && playerIndex > 9) ? 
                        <UserRow item={leaderboardData[playerIndex]} playerIndex={playerIndex}/> : <></>
                    }
                </div>
            </div>
            </>
            }
        </div>
    )
}

export default LeaderboardElement
